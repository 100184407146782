import * as React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import PageWrapper from '../components/PageWrapper'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import HomeIcon from '@mui/icons-material/Home'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const NotFoundPage = () => {
  return (
    <PageWrapper id="404">
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>404 Not Found</title>
      </Helmet>
      <Container maxWidth='sm'>
        <Typography variant='h1' gutterBottom>404 Not Found</Typography>
        <Typography>Sorry, we couldn&apos;t find the page you are referring to.</Typography>
        <br />
        <Link to="/"><Button variant="contained" startIcon={<ArrowBackIcon />}>Back</Button></Link>
      </Container>
    </PageWrapper>

  )
}

export default NotFoundPage
